@import 'tailwindcss/base';

@layer base {
  div[data-theme='default'] {
    /* Base colors */
    --accent-200: #e0f0e1;
    --accent-300: #b3e5b5;
    --accent-400: #1ab667;
    --accent-500: #15a25a;
    --accent-600: #108d4e;
    --accent-700: #0f7541;

    /* Page colors */
    --background-active: #161717;
    --background-page: #1d1e1f;
    --background-section: #232323;

    --brand-success: var(--accent-400);
    --brand-warning: #fad733;
    --brand-danger: #ef9a9a;

    --header-color: #ffffff;
    --text-accent-color: #d9d9d9;
    --text-color: #a2a5a6;
    --text-brand-success: var(--background-page);
    --link-color: #888c90;
    --link-color-hover: #595b5f;

    --border-color: #3a3c3d;

    /* Buttons */
    --btn-default-color: var(--header-color);
    --btn-default-bg: var(--background-page);
    --btn-default-border: var(--background-page);

    --btn-primary-color: var(--header-color);
    --btn-primary-bg: var(--background-page);
    --btn-primary-border: var(--background-page);

    --btn-success-color: var(--header-color);
    --btn-success-bg: var(--brand-success);
    --btn-success-border: var(--btn-success-bg);

    --btn-warning-color: var(--header-color);
    --btn-warning-bg: var(--brand-warning);
    --btn-warning-border: var(--btn-warning-bg);

    --btn-danger-color: var(--header-color);
    --btn-danger-bg: var(--brand-danger);
    --btn-danger-border: var(--btn-danger-bg);

    --btn-dark-color: var(--header-color);
    --btn-dark-bg: var(--bg-backgroundActive);
    --btn-dark-border: var(--bg-backgroundActive);

    /* Inputs */
    --input-border: #595b5f;
    --input-border-focus: #666d73;
    --input-background: #3a3c3d;

    /* Group block */
    --block-default: #ffcc8e;
    --block-progress: #0000004c;

    --amount-shadow: var(--accent-300);

    /* Group colors */
    --text-group-color: #000000;

    --group-color1: #ff8d8d;
    --group-color2: #ffef4a;
    --group-color3: #89ff8e;
    --group-color4: #71fff6;
    --group-color5: #ac58ff;
    --group-color6: #ff8aff;
    --group-color7: #ffcc4a;
    --group-color8: #5874ff;

    /* Scrollbar */
    --scrollbar-track: var(--bg-backgroundActive);
    --scrollbar-thumb: var(--background-page);
    --scrollbar-thumb-hover: var(--input-background);

    /* Player bar */
    --player-control-color: var(--header-color);

    /* Global Font */
    font-family: "Source Sans 3", sans-serif;
  }
}
