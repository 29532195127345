@import 'tailwindcss/components';

@import './themes/themes.css';
@import './vendors/vendors.css';
@import './global/global.css';

@import 'tailwindcss/utilities';

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

