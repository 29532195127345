@import 'tailwindcss/base';

@layer base {
  div[data-theme='pride'] {
    /* Pride colors */
    --accent-200: #e3ad83;
    --accent-300: #e69d66;
    --accent-400: #e6904f;
    --accent-500: #EE7820;
    --accent-600: #F5CF3E;
    --accent-700: #EE7820;

    /* Page colors */
    --background-active: #F5F5F5;
    --background-page: #ffffff;
    --background-section: var(--background-active);

    --brand-success: var(--accent-400);
    --brand-warning: var(--accent-600);
    --brand-danger: #E93524;

    --header-color: #1c1c1c;
    --text-accent-color: #222222;
    --text-color: #5e5e5e;
    --text-brand-success: var(--background-page);
    --link-color: #7a7d80;
    --link-color-hover: #babfc5;

    --border-color: #4c4f50;

    /* Buttons */
    --btn-default-color: var(--header-color);
    --btn-default-bg: var(--background-page);
    --btn-default-border: var(--background-page);

    --btn-primary-color: var(--header-color);
    --btn-primary-bg: var(--background-page);
    --btn-primary-border: var(--background-page);

    --btn-success-color: #F5F5F5;
    --btn-success-bg: var(--brand-success);
    --btn-success-border: var(--btn-success-bg);

    --btn-warning-color: var(--header-color);
    --btn-warning-bg: var(--brand-warning);
    --btn-warning-border: var(--btn-warning-bg);

    --btn-danger-color: #F5F5F5;
    --btn-danger-bg: var(--brand-danger);
    --btn-danger-border: var(--btn-danger-bg);

    --btn-dark-color: var(--header-color);
    --btn-dark-bg: var(--bg-backgroundActive);
    --btn-dark-border: var(--bg-backgroundActive);

    /* Inputs */
    --input-border: #b9bdc4;
    --input-border-focus: #878e94;
    --input-background: #ededed;

    /* Group block */
    --block-default: #ffcc8e;
    --block-progress: #0000004c;

    --amount-shadow: var(--accent-300);

    /* Group colors */
    --text-group-color: #000000;

    --group-color1: #E93524;
    --group-color2: #F5CF3E;
    --group-color3: #3DA860;
    --group-color4: #088FC7;
    --group-color5: #603A83;
    --group-color6: #E93524;
    --group-color7: #d15bd1;
    --group-color8: #a06dd0;

    /* Scrollbar */
    --scrollbar-track: var(--bg-backgroundActive);
    --scrollbar-thumb: var(--background-page);
    --scrollbar-thumb-hover: var(--input-background);
    
    /* Player bar */
    --player-control-color: #000000;
    
    /* Global Font */
      font-family: "Source Sans 3", sans-serif;
  }
}