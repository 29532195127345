/* toggle switcher */
.p-component.switcher {
  height: 1.25rem;
  width: 2.75rem;
}

.p-inputswitch.p-focus .p-inputswitch-slider {
  box-shadow: none;
}

.p-inputswitch .p-inputswitch-slider {
  background-color: var(--link-color);
}

.p-inputswitch .p-inputswitch-slider::before {
  height: 1rem;
  width: 1rem;
  top: 0.75rem;
}

.p-inputswitch .p-inputswitch-slider:hover {
  background-color: var(--link-color) !important;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background-color: var(--brand-success) !important;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:hover {
  background-color: var(--brand-success) !important;
}

/* dropdown */
.p-dropdown,
.p-dropdown-open {
  box-shadow: none !important;
  border: 1px solid var(--input-border) !important;
  color: var(--text-color) !important;
}

.p-dropdown.p-focus,
.p-dropdown-open:hover:not(.p-disabled) {
  border: 1px solid var(--input-border-focus) !important;
  box-shadow: none !important;
}

.p-dropdown-label {
  color: var(--text-color) !important;
}

.p-dropdown-item .p-dropdown.p-focus {
  background-color: var(--link-color-hover) !important;
  color: var(--header-color) !important;
}
.p-dropdown-panel {
  background-color: var(--input-background) !important;
}

.p-dropdown-item {
  color: var(--text-color) !important;
}

.p-dropdown-item:hover,
.p-dropdown-item.p-focus,
.p-dropdown-item.p-highlight {
  background-color: var(--link-color-hover) !important;
  color: var(--header-color) !important;
}

/* dropdown profile menu */
.avatar-dropdown {
  position: absolute !important;
  width: 10rem;
  background-color: var(--background-active) !important;
  color: var(--header-color);
  top: 4rem !important;
  right: 1rem !important;
  left: auto !important;
}

.avatar-dropdown .p-menu-list .p-element .p-menuitem-content>a {
  background: var(--background-active);
}

.avatar-dropdown .p-menu-list .p-element .p-menuitem-content>a:hover {
  background: var(--background-page);
}

.custom-menu {
  width: 255px;
  background-color: var(--background-page) !important;
  color: var(--header-color);
}

.p-menu-list .p-element .p-menuitem-content>a {
  background: var(--background-page);
}

.p-menu-list .p-element .p-menuitem-content>a:hover {
  background: var(--background-active);
}

.p-menuitem-text {
  width: 250px;
  text-align: center;
  color: var(--text-color) !important;
}

.p-menuitem-text:hover {
  color: var(--text-color) !important;
}

label.control-error {
  color: var(--red-300);
}

/* paginator */
.p-paginator.p-component {
  background-color: transparent;
}

button.p-paginator-element:hover,
button.p-paginator-element.p-highlight {
  background-color: var(--input-background) !important;
}

/* input */
input .ng-invalid.ng-touched {
  border: 1px double var(--input-border-focus);
}

input .ng-valid.ng-touched {
  border: 1px double var(--input-border-focus);
}

input .ng-valid.ng-touched.is-invalid {
  border-color: var(--red-300);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s !important;
  -webkit-text-fill-color: var(--text-color) !important;
}

input::placeholder {
  color: var(--text-color) !important;
  opacity: 0.7 !important;
}

/* dialog */
.p-dialog {
  box-shadow: none !important;
}

.p-dialog-content {
  background-color: transparent !important;
  color: white !important;
}

/* toast */
.p-toast-message-content {
  border-left: 0.375rem solid;
}

/* global fonts */

:root { 
  font-family: 'Source Sans Pro', 'sans-serif' !important;
}