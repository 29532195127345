@import 'tailwindcss/base';

@layer base {
  div[data-theme='purple-haze'] {
    /* Base colors */
    --accent-200: #f0e3fb;
    --accent-300: #e7d4fb;
    --accent-400: #dfc1ff;
    --accent-500: #CF9EFF;
    --accent-600: #B176EC;
    --accent-700: #3C083B;
    --accent-800: #050431;

    /* Page colors */
    --background-active: var(--accent-800);
    --background-page: var(--accent-700);
    --background-section: #4b1a4a;

    --brand-success: var(--accent-500);
    --brand-warning: #fad733;
    --brand-danger: #ef9a9a;

    --header-color: #ffffff;
    --text-accent-color: #d9d9d9;
    --text-color: #a2a5a6;
    --text-brand-success: var(--background-page);
    --link-color: #888c90;
    --link-color-hover: #595b5f;

    --border-color: #3a3c3d;

    /* Buttons */
    --btn-default-color: var(--header-color);
    --btn-default-bg: var(--background-page);
    --btn-default-border: var(--background-page);

    --btn-primary-color: var(--header-color);
    --btn-primary-bg: var(--background-page);
    --btn-primary-border: var(--background-page);

    --btn-success-color: var(--header-color);
    --btn-success-bg: var(--brand-success);
    --btn-success-border: var(--btn-success-bg);

    --btn-warning-color: var(--header-color);
    --btn-warning-bg: var(--brand-warning);
    --btn-warning-border: var(--btn-warning-bg);

    --btn-danger-color: var(--header-color);
    --btn-danger-bg: var(--brand-danger);
    --btn-danger-border: var(--btn-danger-bg);

    --btn-dark-color: var(--header-color);
    --btn-dark-bg: var(--bg-backgroundActive);
    --btn-dark-border: var(--bg-backgroundActive);

    /* Inputs */
    --input-border: #595b5f;
    --input-border-focus: #666d73;
    --input-background: #3a3c3d;

    /* Group block */
    --block-default: #ffcc8e;
    --block-progress: #0000004c;

    --amount-shadow: var(--accent-300);

    /* Group colors */
    --text-group-color: #000000;
    
    --group-color1: #FF5BC2;
    --group-color2: #7A3BFF;
    --group-color3: #E231FF;
    --group-color4: #B973FF;
    --group-color5: #dd54aa;
    --group-color6: #f939b3;
    --group-color7: #7A3BFF;
    --group-color8: #E231FF;

    /* Scrollbar */
    --scrollbar-track: var(--bg-backgroundActive);
    --scrollbar-thumb: var(--background-page);
    --scrollbar-thumb-hover: var(--input-background);

    /* Player bar */
    --player-control-color: var(--header-color);

    /* Global Font */
    font-family: "Source Sans 3", sans-serif;
  }
}