  @import 'tailwindcss/base';

  @layer base {
    div[data-theme='neon-club'] {
      /* Base colors */
      --accent-200: #F2FEDC;
      --accent-300: #c7ec9d;
      --accent-400: #CFE485;
      --accent-500: #D8F173;
      --accent-600: #B3CF42;
      --accent-700: #a4c22d;

      /* Page colors */
      --background-active: #212121;
      --background-page: #000000;
      --background-section: #0E0E0E;

      --brand-success: var(--accent-500);
      --brand-warning: #fad733;
      --brand-danger: #ef9a9a;

      --header-color: #ffffff;
      --text-color: #cbd5e1;
      --text-brand-success: var(--background-page);
      --link-color: #94a3b8;
      --link-color-hover: #334155;

      --border-color: #1e293b;

      /* Buttons */
      --btn-default-color: var(--header-color);
      --btn-default-bg: var(--background-page);
      --btn-default-border: var(--background-page);

      --btn-primary-color: var(--header-color);
      --btn-primary-bg: var(--background-page);
      --btn-primary-border: var(--background-page);

      --btn-success-color: var(--background-page);
      --btn-success-bg: var(--brand-success);
      --btn-success-border: var(--btn-success-bg);

      --btn-warning-color: var(--background-page);
      --btn-warning-bg: var(--brand-warning);
      --btn-warning-border: var(--btn-warning-bg);

      --btn-danger-color: var(--header-color);
      --btn-danger-bg: var(--brand-danger);
      --btn-danger-border: var(--btn-danger-bg);

      --btn-dark-color: var(--header-color);
      --btn-dark-bg: var(--bg-backgroundActive);
      --btn-dark-border: var(--bg-backgroundActive);

      /* Inputs */
      --input-border: #334155;
      --input-border-focus: #64748b;
      --input-background: #1e293b;

      /* Group block */
      --block-default: #ffcc8e;
      --block-progress: #0000004c;

      --amount-shadow: var(--accent-300);

    /* Group colors */
      --text-group-color: #000000;

      --group-color1: #FF975C;
      --group-color2: #FFF372;
      --group-color3: #89FF8E;
      --group-color4: #60FFF5;
      --group-color5: #CC9AFF;
      --group-color6: #ff8aff;
      --group-color7: #FF975C;
      --group-color8: #FFF372;
      
      /* Scrollbar */
      --scrollbar-track: var(--bg-backgroundActive);
      --scrollbar-thumb: var(--background-page);
      --scrollbar-thumb-hover: var(--input-background);

      /* Player bar */
      --player-control-color: var(--text-brand-success);
      
      /* Global Font */
      font-family: "Source Sans 3", sans-serif;
    }
}