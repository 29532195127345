@import 'tailwindcss/base';

@layer base {
  div[data-theme='barbie'] {
    /* Base colors */
    --accent-200: #f6bee2;
    --accent-300: #FF95D8;
    --accent-400: #ff62c5;
    --accent-500: #DF309E;
    --accent-600: #FF95D8;
    --accent-700: #FF95D8;

    /* Page colors */
    --background-active: #B9DFFF;
    --background-page: #B9DFFF;
    --background-section: #B2D6F4;
  
    --brand-success: var(--accent-500);
    --brand-warning: #fad733;
    --brand-danger: #e55151;
  
    --header-color: #161717;
    --text-accent-color: #262728;
    --text-color: #5d5d5d;
    --text-brand-success: var(--background-page);
    --link-color: #242e38;
    --link-color-hover: #B9DFFF;
  
    --border-color: #959595;
  
    /* Buttons */
    --btn-default-color: var(--header-color);
    --btn-default-bg: var(--background-page);
    --btn-default-border: var(--background-page);
  
    --btn-primary-color: var(--header-color);
    --btn-primary-bg: var(--background-page);
    --btn-primary-border: var(--background-page);
  
    --btn-success-color: #ffffff;
    --btn-success-bg: var(--brand-success);
    --btn-success-border: var(--btn-success-bg);
  
    --btn-warning-color: var(--header-color);
    --btn-warning-bg: var(--brand-warning);
    --btn-warning-border: var(--btn-warning-bg);
  
    --btn-danger-color: #ffffff;
    --btn-danger-bg: var(--brand-danger);
    --btn-danger-border: var(--btn-danger-bg);
  
    --btn-dark-color: var(--header-color);
    --btn-dark-bg: var(--bg-backgroundActive);
    --btn-dark-border: var(--bg-backgroundActive);
  
    /* Inputs */
    --input-border: #959595;
    --input-border-focus: #3a3c3d;
    --input-background: #d3ebff;
  
    /* Group block */
    --block-default: #ffcc8e;
    --block-progress: #0000004c;
  
    --amount-shadow: var(--accent-300);

    /* Group colors */
    --text-group-color: #000000;

    --group-color1: #33A2FF;
    --group-color2: #FF00A0;
    --group-color3: #DB00FF;
    --group-color4: #CB009E;
    --group-color5: #8DCBFF;
    --group-color6: #33A2FF;
    --group-color7: #FF00A0;
    --group-color8: #DB00FF;

    /* Scrollbar */
    --scrollbar-track: #95cfff;
    --scrollbar-thumb: var(--background-page);
    --scrollbar-thumb-hover: var(--input-background);

    /* Player bar */
    --player-control-color: var(--header-color);

    /* Global Font */
    font-family: "Source Sans 3", sans-serif;
  }
}